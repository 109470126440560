import * as React from 'react'
import auther from '../../../helpers/auth'
import { container } from './Interim.module.scss'
import {useEffect} from "react";
import Loader from "../Loader/Loader";

const auth = auther();

export default props => {
  const auth0Login = () => {
    auth.login()
  };

  // redirect to auto0 login page
  useEffect(()=> {
    auth0Login();
  }, []);

  return (
    <div className={container}>
      <Loader transparent/>
    </div>
  )
}
