import * as React from 'react'
import { Helmet } from 'react-helmet'
import Interim from '../../components/Login/Interim/Interim'

const InterimRoot = () => {
  return (
    <>
      <Helmet>
        <title>Login - Emso</title>
      </Helmet>
      <Interim />
    </>
  )
}

export default InterimRoot;